import "./App.css";
import React from "react";
import { useState, useRef } from "react";
// import { useEffect } from "react";
import {
  Container,
  Stack,
  Button,
  Grid,
  Modal,
  Box,
  Typography,
} from "@mui/material";
import DebtSaving from "./components/DebtMonthlySavings/DebtMonthlySavings";
import emailjs from "@emailjs/browser";
import "./home.scss";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import prmiLogo from "../src/logo/2.png";
import ecoaLogo from "../src/logo/3.png";
import { Analytics } from "@vercel/analytics/react";
import { NumericFormat } from "react-number-format";

function App() {
  // Current mortgage / solar info / income credit
  const [topFields, settopFields] = useState([0]);
  const [noDebtLTV, setNoDebtLTV] = useState();
  const [savingsNoDebt, setSavingsNoDebt] = useState();
  const [noDebtPayment, setNoDebtPayment] = useState();
  const [DebtLTV, setDebtLTV] = useState();
  const [savingsDebt, setSavingsDebt] = useState();
  const [DebtPayment, setDebtPayment] = useState();
  const [installPayments, setinstallPayments] = useState();
  const [revolvePayments, setrevolvePayments] = useState();
  const [previousPaymentTotal, setpreviousPaymentTotal] = useState();
  const [thisPayment, setthisPayment] = useState();
  const [existingPayment, setexistingPayment] = useState();
  const [checked, setChecked] = useState("false");
  const [estimatedPowerBill, setEstimatedPowerBill] = useState();
  const [proposedPowerBill, setProposedPowerBill] = useState();
  const [total, setTotal] = useState();

  // modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "15vp",
    bgcolor: "background.paper",
    border: "2px solid black",
    borderRadius: "8px",
    boxShadow: 24,
    p: 4,
    textAlign: "center",
    color: "#ED8B00",
  };
  // make fetch for rate

  //   useEffect(() => {
  //   let urlencoded = new URLSearchParams();
  // urlencoded.append("username", "PRMI_Prod_API");
  // urlencoded.append("password", "QitjQvz5L3DqvrcVg4cT");
  // urlencoded.append("grant_type", "password");
  // urlencoded.append("client_id", "cN3iTixqEQEhBofDpit5Me00h53dkt0EGN3VxOdU");
  // urlencoded.append("client_secret", "OdQtFqWjKFweENnRcL1WvVbAwCXtGy2h1gIaaH08O8aPjL6E6uhcjRa3BoV0uUbp4ohHq2AWfisv9EKiBTxCJ8FAZv1P1BeWw3tNUCinJOhO4fCDTy3UTIRQml14XvYv");

  // var requestOptions = {
  //   method: 'POST',
  //   body: urlencoded,
  //   redirect: 'follow'
  // };

  // fetch("https://api.stage.polly.io/api/v2/auth/token/", requestOptions)
  //   .then(response => response.text())
  //   .then(result => console.log(result))
  //   .catch(error => console.log('error', error));

  // }, []);
  // Form Handler

  function handleForm(e) {
    const name = e.target.name;
    const value = e.target.value;
    settopFields((values) => ({ ...values, [name]: value }));
  }

  // Liabilities
  const [formFields, setFormFields] = useState([
    { debtName: "", debtsType: "", Balance: "", Payment: "", Rate: "" },
  ]);

  const handleFormChange = (event, index) => {
    let data = [...formFields];
    data[index][event.target.name] = event.target.value;
    setFormFields(data);
  };

  const addFields = () => {
    let object = {
      debtName: "",
      debtsType: "",
      Balance: "",
      Payment: "",
      Rate: "",
    };

    setFormFields([...formFields, object]);
  };

  const removeFields = (index) => {
    let data = [...formFields];
    data.splice(index, 1);
    setFormFields(data);
  };

  // calc

  function monthlyPayment(p, n, i) {
    return Math.round((p * i * Math.pow(1 + i, n)) / (Math.pow(1 + i, n) - 1));
  }

  function monthlyDebtPayment(D, n, i) {
    return Math.round((D * i * Math.pow(1 + i, n)) / (Math.pow(1 + i, n) - 1));
  }

  const Savings = (currentPayment, M, solarSavings) => {
    let newSavings = +currentPayment - +M + +solarSavings;
    return Math.round(newSavings);
  };

  const NewDebtSaving = (
    currentPayment,
    newDebtPayment,
    solarSavings,
    debtPayments
  ) => {
    let newSavings =
      +currentPayment - +newDebtPayment + +solarSavings + debtPayments - 25;
    return Math.round(newSavings);
  };

  const NODEBTLTV = (num1, num2, num) => {
    let ltv = (+num1 + +num2) * 0.015 + +num1 + +num2;
    let value = +num;
    return Math.round((ltv / value) * 100);
  };

  const DEBTLTV = (num1, num2, num, allDebts) => {
    let ltv = (+num1 + +num2 + +allDebts) * 0.015 + +num1 + +num2 + +allDebts;
    let value = +num;
    return Math.round((ltv / value) * 100);
  };

  const handleBoxChange = () => {
    if (checked === "false") {
      return setChecked("true");
    } else {
      return setChecked("false");
    }
  };

  const submit = (e) => {
    // LTV

    e.preventDefault();
    // topFields.Payment - M +

    const num = parseFloat(topFields.currentValue.replace(/,/g, ""));

    let num1 = parseFloat(topFields.Balance.replace(/,/g, ""));
    let num2 = parseFloat(topFields.SolarPanelQuote.replace(/,/g, ""));

    let allDebts = 0;
    let debtPayments = 0;

    formFields.forEach((formFields) => (allDebts += +formFields.Balance));
    formFields.forEach((formFields) => (debtPayments += +formFields.Payment));
    // eslint-disable-next-line
    let installment = formFields.filter((a) => {
      if (a.debtsType === "Installment") {
        return a;
      }
    });
    // eslint-disable-next-line
    let revolving = formFields.filter((a) => {
      if (a.debtsType === "Revolving") {
        return a;
      }
    });
    let existingPandI = parseFloat(topFields.Payment.replace(/,/g, ""));
    let installmentPayments = 0;
    let revolvingPayments = 0;
    let otherPayments = 0;
    // parseFloat(+installment.Payment.replace(/,/g, ""))
    installment.forEach(
      (installment) => (installmentPayments += +installment.Payment)
    );
    revolving.forEach((revolving) => (revolvingPayments += +revolving.Payment));

    setinstallPayments("$" + installmentPayments.toLocaleString());
    setrevolvePayments("$" + revolvingPayments.toLocaleString());
    setthisPayment("$" + otherPayments.toLocaleString());
    setexistingPayment("$" + existingPandI.toLocaleString());
    // monthly payment with debts removed that are blank

    // let M; monthly mortgage payment
    let P = num1 + num2; //principle / initial amount borrowed
    let I = 7.875 / 100 / 12; //monthly interest rate
    let N = 30 * 12; //number of payments months
    let D = (num1 + num2 + allDebts) * 0.02 + (num1 + num2 + allDebts);

    // current payment
    let currentPayment = topFields.Payment;
    // solar savings
    let solarSavings = topFields.MonthlyPowerSaving;
    setEstimatedPowerBill("$" + solarSavings);
    let M = monthlyPayment(P, N, I);
    let newDebtPayment = monthlyDebtPayment(D, N, I);
    let getSavingsNoDebt = Savings(currentPayment, M, solarSavings);
    let newDebtSaving = NewDebtSaving(
      parseFloat(currentPayment.replace(/,/g, "")),
      newDebtPayment,
      solarSavings,
      debtPayments
    );

    let getnoDebtLTV = NODEBTLTV(num1, num2, num);
    let getDebtLTV = DEBTLTV(num1, num2, num, allDebts);

    let previousTotal =
      parseFloat(topFields.Payment.replace(/,/g, "")) +
      +installmentPayments +
      +revolvingPayments +
      +solarSavings;

    let newPowerBill = "$" + 25;
    let totalPayment = +newDebtPayment + 25;

    setpreviousPaymentTotal("$" + previousTotal.toLocaleString());
    setNoDebtLTV(getnoDebtLTV);
    setSavingsNoDebt(getSavingsNoDebt);
    setNoDebtPayment(M);
    setDebtLTV(getDebtLTV);
    setDebtPayment("$" + newDebtPayment.toLocaleString());
    setTotal("$" + totalPayment.toLocaleString());
    setProposedPowerBill(newPowerBill);
    setSavingsDebt(newDebtSaving);
    // console.log(allDebts);
  };
  // emailJS
  // String variables for emailJS
  let topFieldsString = JSON.stringify(topFields);
  let borrowerDebts = JSON.stringify(formFields);
  let noDebtLTVString = JSON.stringify(noDebtLTV);
  let debtLTVString = JSON.stringify(DebtLTV);
  let PandIString = JSON.stringify(noDebtPayment);
  let debtPandIString = JSON.stringify(DebtPayment);
  let noDebtSavingsString = JSON.stringify(savingsNoDebt);
  let debtSavingsString = JSON.stringify(savingsDebt);

  // emil form
  const form = useRef();
  // send email
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        // "service_nzwd6zv",
        // "2nd-Solar-Email",
        // form.current,
        // "auKEdycTRYhONf_t-"
      )
      .then(
        () => {
          // window.location.reload(false);
          handleOpen();
        },
        () => {
          alert("Failed to send the message, please try again");
        }
      );
  };

  return (
    <div className="App">
      <Analytics />
      <div id="back-of-image">
        <div id="bannerImage"></div>
      </div>
      <div id="Form-Section">
        <h1 id="title">Solar Cashflow Forecast</h1>
        <Container maxWidth="lg">
          <form>
            <div id="CenterDiv">
              <div className="CustomerInfo">
                <Stack
                  id="customerInfoStack"
                  direction={{ xs: "column", sm: "row" }}
                  sx={{
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  spacing={{ xs: 1, sm: 2, md: 2 }}
                >
                  <div>
                    <h3>Current Mortgage</h3>
                    <div id="labelDivCurrent">
                      <label>
                        Current Home Value:
                        <NumericFormat
                          id="inputSection"
                          thousandSeparator
                          name="currentValue"
                          value={topFields.currentValue || ""}
                          onChange={(e) => handleForm(e)}
                          className="Value"
                          placeholder="$"
                          // type="number"
                          required
                        />
                      </label>
                    </div>
                    <div id="labelDivCurrent">
                      <label>
                        Current Mortgage Balance:
                        <NumericFormat
                          id="inputSection"
                          thousandSeparator
                          className="Balance"
                          name="Balance"
                          value={topFields.Balance || ""}
                          onChange={(e) => handleForm(e)}
                          placeholder="$"
                          required
                        />
                      </label>
                    </div>
                    <div id="labelDivCurrent">
                      <label>
                        Mortgage Payment Amount:
                        <NumericFormat
                          thousandSeparator
                          id="inputSection"
                          name="Payment"
                          value={topFields.Payment || ""}
                          onChange={(e) => handleForm(e)}
                          className="Payment"
                          placeholder="$"
                          required
                        />
                      </label>
                    </div>
                  </div>
                  <div>
                    <h3 id="solarInfo"> Solar Information</h3>
                    <div id="labelDiv">
                      <label>
                        Estimated Cost of Solar:
                        <NumericFormat
                          thousandSeparator
                          id="inputSection"
                          className="SolarPanelQuote"
                          name="SolarPanelQuote"
                          value={topFields.SolarPanelQuote || ""}
                          onChange={(e) => handleForm(e)}
                          placeholder="$"
                          required
                        />
                      </label>
                    </div>
                    <div id="labelDiv">
                      <label>
                        Current Average Power Bill:
                        <NumericFormat
                          thousandSeparator
                          id="inputSection"
                          className="MonthlyPowerSaving"
                          name="MonthlyPowerSaving"
                          value={topFields.MonthlyPowerSaving || ""}
                          onChange={(e) => handleForm(e)}
                          placeholder="$"
                          required
                        />
                      </label>
                    </div>
                    <h3> Customer Credit</h3>
                    <div id="labelDiv">
                      <label>Credit Score:</label>
                      <select
                        value={topFields.score || ""}
                        onChange={(e) => handleForm(e)}
                        name="score"
                        id="inputSection"
                      >
                        <option value={"default"}>Choose an option</option>
                        <option value="740 - 800">740 - 800</option>
                        <option value="700 - 740">700 - 740</option>
                        <option value="660 - 700">660 - 700</option>
                        <option value="620 - 660">620 - 660</option>
                        <option value="580 - 620">580 - 620</option>
                        <option value="Below 580">Below 580</option>
                      </select>
                    </div>
                    {/* <div id="labelDiv">
                      <label>
                        Estimated Monthly Income:
                        <input
                          id="inputSection"
                          className="Income"
                          value={topFields.Income || ""}
                          onChange={(e) => handleForm(e)}
                          name="Income"
                          placeholder="Monthly Income"
                          type="number"
                        />
                      </label>
                    </div> */}
                  </div>
                </Stack>
              </div>
            </div>
            <div id="SolarInfo">
              <div id="CenterDiv">
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  sx={{
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  spacing={{ xs: 1, sm: 2, md: 2 }}
                ></Stack>
              </div>
            </div>
            <div id="Debts">
              <div id="CenterDiv">
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  sx={{
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  spacing={{ xs: 1, sm: 2, md: 2 }}
                ></Stack>
              </div>
            </div>
          </form>
          <div id="DebtsLiabilities">
            <h3> Liabilities</h3>

            <div id="CenterDivDebts">
              <form>
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  sx={{
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  spacing={{ xs: 1, sm: 2, md: 2 }}
                >
                  <Grid container rowSpacing={2}>
                    {formFields.map((form, index) => {
                      return (
                        <div key={index}>
                          <Stack
                            id="debtStack"
                            direction={{ xs: "column", sm: "row" }}
                            sx={{
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            spacing={{ xs: 1, sm: 2, md: 2 }}
                          >
                            <Grid item lg={20}>
                              <div>
                                <div id="labelDebt">
                                  <label>
                                    Debt Name:
                                    <input
                                      id="inputSection"
                                      required
                                      name="debtName"
                                      className="Debt Name"
                                      placeholder="Debt Name"
                                      type="text"
                                      onChange={(event) =>
                                        handleFormChange(event, index)
                                      }
                                      value={form.debtName}
                                    />
                                  </label>
                                </div>
                                <div id="labelDebt">
                                  <label>Debt Type:</label>
                                  <select
                                    name="debtsType"
                                    value={form.debtsType}
                                    onChange={(event) =>
                                      handleFormChange(event, index)
                                    }
                                    id="inputSection"
                                  >
                                    <option value={"default"}>
                                      Choose an option
                                    </option>
                                    <option name="Installment">
                                      Installment
                                    </option>
                                    <option name="Revolving">Revolving</option>
                                  </select>
                                </div>
                                <div id="labelDebt">
                                  <label>
                                    Debt Balance:
                                    <input
                                      type="number"
                                      id="inputSection"
                                      name="Balance"
                                      className="Balance"
                                      value={form.Balance}
                                      placeholder="$"
                                      onChange={(event) =>
                                        handleFormChange(event, index)
                                      }
                                    />
                                  </label>
                                </div>
                              </div>
                              <div>
                                <div id="labelDebt">
                                  <label>
                                    Payment Amount:
                                    <input
                                      type="number"
                                      id="inputSection"
                                      name="Payment"
                                      className="Payment"
                                      value={form.Payment}
                                      placeholder="$"
                                      onChange={(event) =>
                                        handleFormChange(event, index)
                                      }
                                    />
                                  </label>
                                </div>
                                <div id="labelDebt">
                                  <label>
                                    Debt Rate:
                                    <input
                                      type="number"
                                      id="inputSection"
                                      name="Rate"
                                      className="Rate"
                                      value={form.Rate}
                                      placeholder="Debt Rate (Optional)"
                                      onChange={(event) =>
                                        handleFormChange(event, index)
                                      }
                                    />
                                  </label>
                                  <div id="labelDebt">
                                    <Button
                                      id="deleteButton"
                                      onClick={() => removeFields(index)}
                                    >
                                      <DeleteForeverIcon
                                        sx={{
                                          color: "#e1523d",
                                          fontSize: "35px",
                                        }}
                                      />
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </Grid>
                          </Stack>
                        </div>
                      );
                    })}
                  </Grid>
                </Stack>
              </form>
            </div>
          </div>
          <Button
            id="iconButton"
            onClick={addFields}
            sx={{ color: "#00a3e0", fontSize: "15px" }}
          >
            Add Debt
          </Button>
          <Button
            id="Submit"
            onClick={submit}
            sx={{ color: "#039487", fontSize: "15px" }}
          >
            Calculate
          </Button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <h2 id="modal-H2">We got your submission!</h2>
              <Typography sx={{ padding: "10px" }}>
                Our team will respond to you with a quote within 24 hours
                (monday-friday).
              </Typography>
            </Box>
          </Modal>
        </Container>
      </div>
      <div id="calcDiv">
        <Container>
          <h2 id="titleCalcHeader">Estimated Solar Savings Snapshot</h2>
          <h2 id="titleCalc">
            Forecasted Savings of Combining Mortgage + Solar + Paying Off Debt
          </h2>
          <Stack
            direction={{ xs: "column", md: "row" }}
            sx={{
              justifyContent: "center",
              alignItems: "center",
              paddingBottom: "5px",
            }}
            spacing={{ xs: 1, sm: 2, md: 2 }}
          >
            {savingsDebt > 0 && <DebtSaving savingsDebt={savingsDebt ?? []} />}
            {savingsDebt < 0 && <DebtSaving savingsDebt={savingsDebt ?? []} />}

            {/* payment overview */}
            <Stack
              direction={{ xs: "column", sm: "row" }}
              sx={{
                justifyContent: "center",
                alignItems: "center",
                paddingBottom: "5px",
              }}
              spacing={{ xs: 1, sm: 2, md: 2 }}
            >
              <div id="paymentOverview">
                <h2 id="paymentH2">Payment Overview</h2>
                <p id="paymentDisclaimer">
                  This compares the borrower’s existing monthly mortgage payment
                  and other obligations against the proposed loan with debt
                  consolidation.
                </p>
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 6 }}>
                  {/* row 1 */}
                  <Grid item xs={2} sm={4} md={4}></Grid>
                  <Grid id="gridBold" item xs={2} sm={4} md={4}>
                    Existing
                  </Grid>
                  <Grid id="gridBold" item xs={2} sm={4} md={4}>
                    Proposed
                  </Grid>
                  {/* row 2 */}
                  <Grid id="gridBold" item xs={2} sm={4} md={4}>
                    principle & Interest
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <h3>{existingPayment}</h3>
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <h3>{DebtPayment}</h3>
                  </Grid>
                  {/* row 3 */}
                  <Grid id="gridBold" item xs={2} sm={4} md={4}>
                    Installments
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <h3>{installPayments}</h3>
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <h3>{thisPayment}</h3>
                  </Grid>
                  {/* row 4 */}
                  <Grid id="gridBold" item xs={2} sm={4} md={4}>
                    Revolving
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <h3>{revolvePayments}</h3>
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <h3>{thisPayment}</h3>
                  </Grid>
                  {/* row 5 */}
                  <Grid id="gridBold" item xs={2} sm={4} md={4}>
                    Current Power Bill
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <h3>{estimatedPowerBill}</h3>
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <h3>{proposedPowerBill}</h3>
                  </Grid>
                  {/* row 6 */}
                  <Grid
                    className="totalH3"
                    id="gridBold"
                    item
                    xs={2}
                    sm={4}
                    md={4}
                  >
                    Total
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <h3 id="totalH3">{previousPaymentTotal}</h3>
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <h3 id="finalPaymentH3">{total}</h3>
                  </Grid>
                </Grid>
              </div>
            </Stack>
          </Stack>
          <div id="snapShotDiv">
            <p id="snapShotP">
              Lowtility is a one-of-a-kind Lowtility's mission is clear: to
              enhance the cashflow of homeowners by adding solar. This one of a
              kind proprietary program is designed to reset and increase
              homeowners cashflow. Empowering homeowners to optimize their
              financial resources, enabling them to better manage their
              expenses, invest wisely, and ultimately achieve their financial
              goals.
            </p>
          </div>
        </Container>
      </div>
      {/* emailJS */}
      <h2 id="step2">Ready for Step 2</h2>
      <Container maxWidth="md">
        <p id="step2P">
          If your customer liked the estimated savings above… let’s go to step
          two with more accurate numbers.
        </p>
        <p id="step2P">
          Submit this form and we’ll create a more detailed quote for your
          customer including a soft credit pull and will follow up with you to
          review. Our team will respond to you with a quote within 24 hours
          (monday-friday).
        </p>
      </Container>
      <Container maxWidth="md">
        <div className="contact-form">
          <form ref={form} onSubmit={sendEmail}>
            {/* <form> */}
            <Stack
              direction={{ xs: "column", md: "row" }}
              sx={{
                justifyContent: "center",
                alignItems: "center",
                paddingBottom: "5px",
              }}
              spacing={{ xs: 1, sm: 2, md: 2 }}
            >
              <ul>
                <h3>Client Information</h3>
                <li className="half">
                  <input
                    type="text"
                    name="FirstName"
                    placeholder="First Name"
                    required
                  />
                </li>
                <li className="half">
                  <input
                    type="text"
                    name="LastName"
                    placeholder="Last Name"
                    required
                  />
                </li>
                <li className="half">
                  <input
                    placeholder="Phone Number"
                    type="text"
                    name="PhoneNumber"
                    required
                  />
                </li>
                <li className="half">
                  <input
                    placeholder="Email"
                    type="email"
                    name="email"
                    required
                  />
                </li>
                <li>
                  <input
                    placeholder="Address"
                    type="address"
                    name="address"
                    required
                  />
                </li>
                <li className="half">
                  <input placeholder="APT" type="apt" name="apt" />
                </li>
                <li className="half">
                  <input placeholder="City" type="city" name="city" required />
                </li>
                <li className="half">
                  {/* <input
                    placeholder="State"
                    type="state"
                    name="state"
                    required
                  /> */}
                  {/* <label>Credit Score:</label> */}
                  <select
                    id="stateSelect"
                    placeholder="State"
                    type="state"
                    name="state"
                    required
                  >
                    <option value={"default"}>State</option>
                    <option value="AL">Alabama</option>
                    <option value="AK">Alaska</option>
                    <option value="AZ">Arizona</option>
                    <option value="AR">Arkansas</option>
                    <option value="CA">California</option>
                    <option value="CO">Colorado</option>
                    <option value="CT">Connecticut</option>
                    <option value="DE">Delaware</option>
                    <option value="FL">Florida</option>
                    <option value="GA">Georgia</option>
                    <option value="HI">Hawaii</option>
                    <option value="ID">Idaho</option>
                    <option value="IL">Illinois</option>
                    <option value="IN">Indiana</option>
                    <option value="IA">Iowa</option>
                    <option value="KS">Kansas</option>
                    <option value="KY">Kentucky</option>
                    <option value="LA">Louisiana</option>
                    <option value="ME">Maine</option>
                    <option value="MD">Maryland</option>
                    <option value="MA">Massachusetts</option>
                    <option value="MI">Michigan</option>
                    <option value="MN">Minnesota</option>
                    <option value="MS">Mississippi</option>
                    <option value="MO">Missouri</option>
                    <option value="MT">Montana</option>
                    <option value="NE">Nebraska</option>
                    <option value="NV">Nevada</option>
                    <option value="NH">New Hampshire</option>
                    <option value="NJ">New Jersey</option>
                    <option value="NM">New Mexico</option>
                    <option value="NY">New York</option>
                    <option value="NC">North Carolina</option>
                    <option value="ND">North Dakota</option>
                    <option value="OH">Ohio</option>
                    <option value="OK">Oklahoma</option>
                    <option value="OR">Oregon</option>
                    <option value="PA">Pennsylvania</option>
                    <option value="RI">Rhode Island</option>
                    <option value="SC">South Carolina</option>
                    <option value="SD">South Dakota</option>
                    <option value="TN">Tennessee</option>
                    <option value="TX">Texas</option>
                    <option value="UT">Utah</option>
                    <option value="VT">Vermont</option>
                    <option value="VA">Virginia</option>
                    <option value="WA">Washington</option>
                    <option value="WV">West Virginia</option>
                    <option value="WI">Wisconsin</option>
                    <option value="WY">Wyoming</option>
                  </select>
                </li>
                <li className="half">
                  <input
                    placeholder="Zip Code"
                    type="zip"
                    name="zip"
                    required
                  />
                </li>
                <li>
                  <textarea placeholder="Message" name="message"></textarea>
                </li>
                <li>
                  <label id="softPull">
                    Client approves a soft pull
                    <input
                      id="softPullCheckBox1"
                      placeholder="false"
                      type="checkbox"
                      name="soft-pull-checkbox1"
                      value={checked}
                      // onClick={e => setChecked(true)}
                      onClick={(e) => handleBoxChange(e)}
                    ></input>
                  </label>
                </li>
              </ul>
              {/* Solar Rep*/}
              <ul id="solarRep">
                <h3>Solar Rep Information</h3>
                <li className="half">
                  <input
                    type="text"
                    name="solar-first-name"
                    placeholder="First Name"
                    required
                  />
                </li>
                <li className="half">
                  <input
                    type="text"
                    name="solar-last-name"
                    placeholder="Last Name"
                    required
                  />
                </li>
                <li className="half">
                  <input
                    placeholder="Phone Number"
                    type="text"
                    name="solar-number"
                    required
                  />
                </li>
                <li className="half">
                  <input
                    placeholder="Email"
                    type="email"
                    name="solar-email"
                    required
                  />
                </li>
                <li>
                  <input
                    placeholder="Company"
                    type="company"
                    name="company"
                    required
                  />
                </li>
                <li>
                  <textarea
                    id="SolarMessage"
                    placeholder="Message"
                    name="solar-Message"
                  ></textarea>
                </li>
                {/* test variable */}

                <li>
                  <input
                    type="hidden"
                    id="First-Section"
                    placeholder="First-Section"
                    name="First-Section"
                    value={topFieldsString}
                  ></input>
                </li>
                <input
                  id="softPullCheckBox"
                  placeholder="false"
                  type="hidden"
                  name="soft-pull-checkbox"
                  value={checked}
                  onClick={(e) => setChecked(true)}
                ></input>
                <li>
                  <input
                    type="hidden"
                    id="debts"
                    placeholder="debts"
                    name="debts"
                    value={borrowerDebts}
                  ></input>
                </li>
                <li>
                  <input
                    type="hidden"
                    id="no-debt-ltv"
                    placeholder="no-debt-ltv"
                    name="no-debt-ltv"
                    value={noDebtLTVString}
                  ></input>
                </li>
                <li>
                  <input
                    type="hidden"
                    id="debt-ltv"
                    placeholder="debt-ltv"
                    name="debt-ltv"
                    value={debtLTVString}
                  ></input>
                </li>
                <li>
                  <input
                    type="hidden"
                    id="payment"
                    placeholder="payment"
                    name="payment"
                    value={PandIString}
                  ></input>
                </li>
                <li>
                  <input
                    type="hidden"
                    id="payment-debt"
                    placeholder="payment-debt"
                    name="payment-debt"
                    value={debtPandIString}
                  ></input>
                </li>
                <li>
                  <input
                    type="hidden"
                    id="savings"
                    placeholder="savings"
                    name="savings"
                    value={noDebtSavingsString}
                  ></input>
                </li>
                <li>
                  <input
                    type="hidden"
                    id="savings-debt"
                    placeholder="savings-debt"
                    name="savings-debt"
                    value={debtSavingsString}
                  ></input>
                </li>
              </ul>
            </Stack>
            {/* <li> */}
            {/* <input
                  type="submit"
                  className="flat-button"
                  value="Send"
                  // to="kfarshchian@gmail.com"
                /> */}
            {/* </li> */}
            <Button type="submit" value="SEND" className="flat-button">
              Submit a Customer
            </Button>
          </form>
        </div>
      </Container>
      <Container maxWidth="md">
        <p id="disclaimerNote">
          The information provided by these calculators is for illustrative
          purposes only. The default figures shown are hypothetical and may not
          be applicable to your individual situation. Be sure to consult a
          financial professional prior to relying on the results. The calculated
          results are intended for illustrative purposes only and accuracy is
          not guaranteed.
        </p>
        <p id="disclaimerNote">
          *Calculator Disclaimer: Information and interactive calculators are
          made available to you as self-help tools for your independent use and
          are not intended to provide investment advice. We cannot and do not
          guarantee their applicability or accuracy in regards to your
          individual circumstances. All examples are hypothetical and are for
          illustrative purposes. We encourage you to seek personalized advice
          from qualified professionals regarding all personal finance issues.
          TRID Disclaimer: Your actual rate, payment and costs could be higher.
          Get an official loan estimate before choosing a loan.
        </p>
        <Stack
          direction={{ xs: "column", md: "row" }}
          sx={{
            justifyContent: "center",
            alignItems: "center",
            // borderBottom: "1px solid black",
            paddingBottom: "5px",
          }}
          spacing={{ xs: 1, sm: 2, md: 2 }}
        >
          <img id="prmiLogo" src={prmiLogo} alt="prmi logo" />
          <img id="ecoaLogo" src={ecoaLogo} alt="ecoa logo" />
        </Stack>
      </Container>
    </div>
  );
}

export default App;
